<template>
  <div class="wapModel layoutTableCard" style="padding: 0;">
    <div v-for="(item, index) in items" :key="index" class="tableGroup">
      <div class="tableList">
        <div class="control-field">{{ $g("trainingDateTime") }}:&nbsp;</div>
        <div class="control-value">
          {{ item.trainingDateTime }}
        </div>
      </div>
      <div class="tableList" aria-label="">
        <div class="control-field">{{ $g("trainingPin") }}:&nbsp;</div>
        <div class="control-value">
          {{ item.trainingPin }}
        </div>
      </div>
      <div class="tableList">
        <div class="control-field">{{ $g("action") }}:&nbsp;</div>
        <div class="control-value">
          <k-link @click="openWindow(item)" v-if="item.showUrl">
            {{ item.trainingLinkDisplay }}
          </k-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    fields: {
      type: Array,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    openWindow: {
      type: Function,
      default: null,
    },
  },
};
</script>