<template>
  <div>
    <k-alert-msg ref="lblMsg" />
    <div class="cardHead">
      {{ groupName }}
    </div>
    <div class="cardBody">
      <konn-grid
        ref="selectableTable"
        :has-check-box="false"
        :autoBind="false"
        :has-pager="false"
        :fields="fields"
        class="wapTableHide"
      >
        <template #cell(trainingDateTime)="row">
          <div>{{ row.item.trainingDateTime }}</div>
        </template>
        <template #cell(action)="row">
          <k-link @click="openWindow(row.item)" v-if="row.item.showUrl">
            {{row.item.trainingLinkDisplay}}
          </k-link>
        </template>
      </konn-grid>
      <trainingAccessWap :fields="fields" :items="value" :openWindow="openWindow" />
    </div>
  </div>
</template>

<script>
import trainingAccessWap from "./training-access-wap.vue";
export default {
  components: { trainingAccessWap },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    groupName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fields: [
        { key: "trainingDateTime", label: "trainingDateTime" },
        { key: "trainingPin", label: "trainingPin" },
        { key: "action", label: "action" },
      ],
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    openWindow(item) {
      window.open(item.trainingUrl, "_blank");
    },
    initData() {
      this.value.forEach((item) => {
        this.$refs.selectableTable.items.push(item);
      });
    },
  },
};
</script>
