var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"photoList"},[_c('homeItem',{staticClass:"boxMargin",attrs:{"item-title":_vm.$g('location')}},[_c('div',{staticClass:"locationBox"},[_c('div',{staticClass:"picBox"},[_c('div',{staticClass:"picList"},[_c('div',{staticClass:"picList_title"},[_c('div',{staticClass:"flex-shrink-0 mr-3"},[_c('img',{attrs:{"src":_vm.mapPoint && _vm.mapPoint.active ? _vm.signBlue : _vm.signRed,"alt":"maoIcon"}})]),_c('div',{staticStyle:{"flex":"1"}},[_c('div',{staticClass:"featuredFont font-weight-bold"},[_vm._v(" "+_vm._s(_vm.facilityName)+" ")]),(_vm.facilityRoom)?_c('div',[_vm._v(" "+_vm._s(_vm.$g("facilityRoom"))+": "+_vm._s(_vm.facilityRoom)+" ")]):_vm._e(),_c('div',[_vm._v(_vm._s(_vm.$g("facilityAddress"))+": "+_vm._s(_vm.facilityAddress))])])]),(_vm.facilityPhoto.length > 0)?_c('div',{staticClass:"box"},[(_vm.facilityPhoto.length > 1)?_c('b-icon',{class:[
                'icons1',
                'icons',
                { hiddenPic: _vm.leftBtnShow },
                'flex-shrink-0',
                'mr-2',
              ],attrs:{"icon":"chevron-left","font-scale":"2.5"},on:{"click":function($event){return _vm.left()}}}):_vm._e(),_c('div',{staticClass:"imgbox"},[_c('ul',{ref:"ul",style:({ transform: `translateX(${_vm.slidenum}px)` })},_vm._l((_vm.facilityPhoto),function(item,index){return _c('li',{key:index,staticClass:"slide",on:{"click":function($event){return _vm.tip(item)}}},[_c('img',{attrs:{"src":item,"alt":"error","crossorigin":"anonymous"}})])}),0)]),(_vm.facilityPhoto.length > 1)?_c('b-icon',{class:[
                'icons1',
                'icons',
                { hiddenPic: _vm.rightBtnShow },
                'flex-shrink-0',
                'ml-2',
              ],attrs:{"icon":"chevron-right","font-scale":"2.5"},on:{"click":function($event){return _vm.right()}}}):_vm._e()],1):_vm._e()])]),_c('div',{staticClass:"map"},[_c('k-map',{ref:"map",attrs:{"map-init":_vm.onMapInit,"map-clicked":_vm.onMapClicked}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }