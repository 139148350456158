export const EnumButtonDisplay =
{
    None: 0,
    IsShowAccept: 1,
    IsShowReject: 2,
    IsShowWithdraw: 3,
    IsShowDelete: 4,
    IsReschedule: 5,
    IsSignUp: 6,
    IsViewCourseInfo: 7
}

export const EnumTrainingConfirmStatus =
{
    None: 0,
    Accepted: 1,
    NoAccepted: 2,
    Withdraw: 4
}