<template>
  <div class="wapModel layoutTableCard">
    <div v-for="(item, index) in itemsData" :key="index" class="tableGroup">
      <div class="tableList justify-content-between">
        <k-link
          target="_blank"
          v-if="item.materialType == materialTypes.website"
          @click="clickLink(item)"
          >{{ item.title }}</k-link
        >
        <div v-else>{{ item.title }}</div>
        <span
          style="
            white-space: nowrap;
            margin-left: 10px;
            min-width: 75px;
            text-align: right;
          "
        >
          {{ item.trainingMaterialViewedStr }}
        </span>
      </div>
      <div class="tableList">
        {{ item.description }}
      </div>

      <div class="tableList mt-3 justify-content-end">
        <b-icon
          icon="cloud-arrow-down-fill"
          @click="downloadPath(item)"
          v-if="
            (item.materialType == materialTypes.document ||
              item.materialType == materialTypes.video) &&
            item.documentId != null
          "
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    fields: {
      type: Array,
      default: null,
    },
    items: {
      type: Array,
      default: [],
    },
    clickLink: {
      type: Function,
      default: null,
    },
    downloadPath: {
      type: Function,
      default: null,
    },
    materialTypes: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      itemsData: JSON.parse(JSON.stringify(this.items)).reverse(),
    };
  },
};
</script>
<style lang="scss" scoped>
.groupWarin {
  background: #ffff80;
}
.layoutTableCard .tableGroup .tableList {
  flex-wrap: nowrap;
}
</style>