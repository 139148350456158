<template>
  <div class="wapModel layoutTableCard">
    <div v-for="(item, index) in items" :key="index" class="tableGroup">
      <div class="tableList font-weight-bold">
        {{ item.title }}
      </div>
      <div class="tableList">
        <div class="control-field">{{ fields[1].label }}:&nbsp;</div>
        <div class="control-value">
          {{ item.scoreNeededToPassCourse }}
        </div>
      </div>
      <div class="tableList" aria-label="">
        <div class="control-field">{{ fields[2].label }}:&nbsp;</div>
        <div class="control-value">
          {{ item.attemptsRemaining }}
        </div>
      </div>
      <div class="tableList" aria-label="">
        <div class="control-field">{{ fields[3].label }}:&nbsp;</div>
        <div class="control-value">
          {{ item.quizScore }}
        </div>
      </div>
      <div class="tableList mt-3 justify-content-end">
        <k-button
          @click="takeQuiz(item)"
          v-if="item.isShowTakeQuiz"
          variant="default"
        >
          takeQuiz
        </k-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    fields: {
      type: Array,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    clickLink: {
      type: Function,
      default: null,
    },
    takeQuiz: {
      type: Function,
      default: null,
    },
  },
};
</script>