<template>
  <div>
    <home-item :item-title="groupName">
      <konn-grid
        ref="selectableTable"
        :has-check-box="false"
        :autoBind="false"
        :has-pager="false"
        :fields="fields"
        class="wapTableHide"
      >
        <template #head(function)="row">
          <div>
            {{ row.label }}
          </div>
        </template>
        <template #cell(function)="row">
          <k-button
            @click="takeQuiz(row.item)"
            v-if="row.item.isShowTakeQuiz"
            variant="default"
          >
            takeQuiz
          </k-button>
        </template>
      </konn-grid>
      <trainingQuizWap :fields="fields" :items="value" :takeQuiz="takeQuiz" />
    </home-item>
  </div>
</template>

<script>
import HomeItem from "../../components/home-item.vue";
import trainingQuizWap from "./training-quize-wap.vue";
export default {
  components: { HomeItem, trainingQuizWap },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    groupName: {
      type: String,
      default: "",
    },
    quizDueDate: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fields: [
        { key: "title", label: "quizName" },
        { key: "scoreNeededToPassCourse", label: "scoreNeededToPassCourse" },
        { key: "attemptsRemaining", label: "attemptsRemaining" },
        { key: "quizDueDate", label: "quizDueDate" },
        { key: "quizScore", label: "quizScore" },
        { key: "function", label: "action", width: "150px" },
      ],
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.value.forEach((item) => {
        this.$refs.selectableTable.addNewRow(item, true);
      });
    },
    takeQuiz(item) {
      this.$router.push({
        name: "takeQuiz",
        query: {
          id: item.eleClassQuizId,
          courseInfoId: this.$route.query.id,
        },
      });
    },
  },
};
</script>
