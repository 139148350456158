import http from "../../node_modules/fe-infrastractures/src/utils/http";
import { EnumTrainingConfirmStatus } from "@/utils/enums/enumTraining";
import { globalEnv } from "fe-infrastractures";

const baseURL = globalEnv.VUE_APP_STD_URL;
export const getAcceptConfig = () => {
  return http.get("/api/trainings/confirm-reason/accept", {}, { loading: false, baseURL });
};
export const getDeclineConfig = () => {
  return http.get("/api/trainings/confirm-reason/decline", {}, { loading: false, baseURL });
};
export const getWithdrawConfig = () => {
  return http.get("/api/trainings/confirm-reason/withdraw", {}, { loading: false, baseURL });
};

// api/trainings/actions/available-training-class-page
export const getSignUpTrainingClassPage = (data) => {
  return http.post("/api/trainings/actions/available-training-class-page", data, {
    useBody: true,
    loading: false,
    baseURL,
  });
};
export const getRescheduleTrainingClassPage = (data) => {
  return http.post("/api/trainings/actions/reschedule-training-class-page", data, {
    useBody: true,
    loading: false,
    baseURL,
  });
};

export const addSignUpClass = (data) => {
  return http.post("/api/trainings/actions/assign", data, { useBody: true, loading: false, baseURL });
};
export const addRescheduleClass = (data) => {
  return http.post("/api/trainings/actions/reschedule-assign", data, { useBody: true, loading: false, baseURL });
};

export const getCoursesPage = (data) => {
  return http.post("/api/trainings/actions/training-class-page", data, { useBody: true, loading: false, baseURL });
};
export const setCoursesStatus = (data) => {
  switch (data.confirmStatus) {
    case EnumTrainingConfirmStatus.Accepted:
      return http.post("/api/trainings/action/accept", data, { useBody: true, loading: false, baseURL });
    case EnumTrainingConfirmStatus.NoAccepted:
      return http.post("/api/trainings/action/decline", data, { useBody: true, loading: false, baseURL });
    case EnumTrainingConfirmStatus.Withdraw:
      return http.post("/api/trainings/action/withdraw", data, { useBody: true, loading: false, baseURL });
    default:
      break;
  }
};

export const getMaterialsPage = (data) => {
  return http.post("/api/training-materials/actions/training-material-list", data, {
    useBody: true,
    loading: false,
    baseURL,
  });
};

// api/docs/actions/download

export const isExit = (model) => {
  return http.post(`/api/docs/actions/IsExit`, { model }, { loading: true, baseURL });
};

export const isExitFile = (id) => {
  return http.get(`/api/docs/actions/IsExitFile/` + id);
};
export const downloadFile = (id) => {
  return http.down(`/api/docs/actions/download/` + id);
};
export const openFile = (id) => {
  return http.get(`/api/docs/actions/open/` + id);
};


export const downloadDocumentUrl = (model) => {
  return http.down("/api/docs/actions/download", { model }, { loading: false, baseURL });
};

export const setView = (data) => {
  return http.post("/api/training-materials/actions/view-training-material", data, {
    useBody: true,
    loading: false,
    baseURL,
  });
};

export const getCourseInfo = (eleClassId) => {
  return http.get(`api/trainings/class/${eleClassId}`, null, { loading: false, baseURL });
};

export const getTakeQuizInfo = (eleClassQuizId) => {
  return http.post(`api/take-quizs/${eleClassQuizId}`, null, { loading: false, baseURL });
};

// api/take-quizs
export const saveQuiz = (data) => {
  return http.post(`api/take-quizs`, data, { useBody: true, baseURL });
};

// api/trainings/actions/has-available-classtype
export const isNoClassData = (data) => {
  return http.post("/api/trainings/actions/has-available-classtype", data, { useBody: true, loading: false, baseURL });
};

export const showTakeQuiz = (data) => {
  return http.get("/api/trainings/class/show-take-quiz", data, { loading: false, baseURL });
};

