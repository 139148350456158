<template>
  <div class="photoList">
    <homeItem class="boxMargin" :item-title="$g('location')">
      <div class="locationBox">
        <div class="picBox">
          <div class="picList">
            <div class="picList_title">
              <div class="flex-shrink-0 mr-3">
                <img
                  :src="mapPoint && mapPoint.active ? signBlue : signRed"
                  alt="maoIcon"
                />
              </div>
              <div style="flex: 1">
                <div class="featuredFont font-weight-bold">
                  {{ facilityName }}
                </div>
                <div v-if="facilityRoom">
                  {{ $g("facilityRoom") }}: {{ facilityRoom }}
                </div>
                <div>{{ $g("facilityAddress") }}: {{ facilityAddress }}</div>
              </div>
            </div>
            <div class="box" v-if="facilityPhoto.length > 0">
              <b-icon
                v-if="facilityPhoto.length > 1"
                icon="chevron-left"
                font-scale="2.5"
                :class="[
                  'icons1',
                  'icons',
                  { hiddenPic: leftBtnShow },
                  'flex-shrink-0',
                  'mr-2',
                ]"
                @click="left()"
              />
              <div class="imgbox">
                <ul
                  ref="ul"
                  :style="{ transform: `translateX(${slidenum}px)` }"
                >
                  <li
                    v-for="(item, index) in facilityPhoto"
                    class="slide"
                    :key="index"
                    @click="tip(item)"
                  >
                    <img :src="item" alt="error" crossorigin="anonymous" />
                  </li>
                </ul>
              </div>
              <b-icon
                v-if="facilityPhoto.length > 1"
                icon="chevron-right"
                font-scale="2.5"
                :class="[
                  'icons1',
                  'icons',
                  { hiddenPic: rightBtnShow },
                  'flex-shrink-0',
                  'ml-2',
                ]"
                @click="right()"
              />
            </div>
          </div>
        </div>
        <div class="map">
          <k-map ref="map" :map-init="onMapInit" :map-clicked="onMapClicked" />
        </div>
      </div>
    </homeItem>
  </div>
</template>

<script>
import imgnoPhoto from "@/assets/image/noPhoto.png";
import HomeItem from "../../components/home-item.vue";
import signRed from "@/assets/image/signRed.png";
import signBlue from "@/assets/image/signBlue.png";
import {
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
} from "fe-infrastractures";
export default {
  components: { HomeItem },
  props: {
    photoMap: {
      type: Object,
      default: null,
    },
    mapPoint: {
      type: Object,
      default: null,
    },
  },
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );

    return {
      curPageConfigAndDefaultPageConfig,
      getConfigVal,
      signRed,
      signBlue,
      now_index: 0,
      slidenum: 0,
      fullScreenNum: 4,
      facilityName: "",
      facilityAddress: "",
      facilityRoom: "",
      facilityPhoto: [],
      slide_array: [imgnoPhoto],
      rightBtnShow: false,
      leftBtnShow: true,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.facilityName = this.photoMap?.facilityName;
      this.facilityAddress = this.photoMap?.facilityAddress;
      this.facilityRoom = this.photoMap?.facilityRoom;
      this.facilityPhoto =
        this.photoMap?.facilityPhotoList?.length > 0
          ? this.photoMap?.facilityPhotoList
          : [];
    },
    left() {
      if (!this.now_index) return;
      this.now_index--;
      this.slidenum = "-" + 160 * this.now_index;
      this.$forceUpdate();
      if (this.now_index == 0) {
        this.leftBtnShow = true;
        this.rightBtnShow = false;
      } else {
        this.rightBtnShow = false;
        this.leftBtnShow = false;
      }
    },
    right() {
      if (this.now_index + this.fullScreenNum === this.slide_array.length)
        return;
      this.now_index++;
      this.slidenum = "-" + 160 * this.now_index;
      this.$forceUpdate();
      if (this.now_index == this.facilityPhoto.length - 1) {
        this.leftBtnShow = false;
        this.rightBtnShow = true;
      } else {
        this.leftBtnShow = false;
        this.rightBtnShow = false;
      }
    },
    tip(item) {
      console.log(item);
    },
    onMapInit(map) {
      if (this.mapPoint) {
        this.$refs.map.addGeoJson(this.mapPoint);
      }
      let infowindow = new google.maps.InfoWindow({
        pixelOffset: new google.maps.Size(0, -20), // offset for icon
      });
      let self = this;
      map.data.setStyle({
        icon: signRed,
      });
      let marker = new google.maps.Marker({ icon: signBlue });
      map.data.addListener("click", function (event) {
        infowindow.setContent(event.feature.getProperty("title"));
        infowindow.setPosition(event.feature.getGeometry().get());
        infowindow.open(self.$refs.map.getMap());
        marker.setPosition(event.feature.getGeometry().get());
        marker.setMap(self.$refs.map.getMap());
        self.mapPoint.active = true;
        self.$forceUpdate();
      });
      setTimeout(() => {
        map.setZoom(
          this.getConfigVal(
            this.curPageConfigAndDefaultPageConfig,
            "MapZoom",
            "zoom"
          )
        );
      }, 500);
    },
    onMapClicked() {
      return "building test";
    },
  },
};
</script>

<style lang="scss" scoped>
.photoList {
  .boxMargin {
    margin-bottom: 50px;
    .locationBox {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .picBox {
        width: calc(50% - 10px);
        .picList {
          width: 100%;
          margin-bottom: 20px;
          .picList_title {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;
          }
          .box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 90%;
            .hiddenPic {
              visibility: hidden;
            }
            .imgbox {
              width: 95%;
              margin: 0;
              overflow: hidden;
              ul {
                width: 1650px;
                transition: 1s;
                padding-left: 0;
                //display: flex;
                li {
                  width: 150px;
                  height: 160px;
                  overflow: hidden;
                  float: left;
                  margin-right: 10px;
                  position: relative;
                  img {
                    max-height: 100%;
                    width: 100%;
                    height: 100%;
                  }
                  .zhezhao {
                    width: 100%;
                    height: 100%;
                    background: rgba(155, 155, 155, 0.7);
                    position: absolute;
                    z-index: 11;
                    top: 0;
                    left: 0;
                  }
                }
              }
            }
          }
        }
      }
      .map {
        width: calc(50% - 10px);
      }
    }
  }
}
@media (max-width: 991px) {
  .locationBox {
    flex-direction: column-reverse;
    .picBox {
      width: 100% !important;
    }
    .map {
      height: 400px;
      width: 100% !important;
      margin-bottom: 30px;
    }
  }
}
</style>
