<template>
  <homeItem class="boxMargin" :item-title="descTitle">
    <div v-html="desc"></div>
  </homeItem>
</template>
<script>
import HomeItem from "../../components/home-item.vue";
export default {
  components: { HomeItem },
  props: {
    descTitle: {
      type: String,
      default: "",
    },
    desc: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
</style>