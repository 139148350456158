<template>
  <div>
    <k-alert-msg ref="lblMsg" />
    <div class="cardHead">
      {{ groupName }}
    </div>
    <div class="cardBody">
      <konn-grid
        ref="selectableTable"
        :has-check-box="false"
        :autoBind="false"
        :has-pager="false"
        :fields="fields"
        class="wapTableHide"
      >
        <template #cell(title)="row">
          <k-link
            target="_blank"
            v-if="isShowLink(row.item)"
            @click="clickLink(row.item)"
            >{{ row.value }}</k-link
          >
          <div v-else>{{ row.value }}</div>
        </template>
        <template #cell(function)="row">
          <b-icon
            icon="cloud-arrow-down-fill"
            @click="downloadPath(row.item)"
            v-if="
              (row.item.materialType == materialTypes.document ||
                row.item.materialType == materialTypes.video) &&
              row.item.documentId != null
            "
          />
        </template>
      </konn-grid>
      <trainingMaterialsWap
        :fields="fields"
        :items="value"
        :clickLink="clickLink"
        :downloadPath="downloadPath"
        :material-types="materialTypes"
      />
    </div>
  </div>
</template>

<script>
import { downloadFile, isExitFile, setView } from "@/api/training";
import trainingMaterialsWap from "./training-materials-wap.vue";
import { materialTypesEnum } from "@/utils/enums/training.js";
import { repResult } from "fe-infrastractures";
export default {
  components: {  trainingMaterialsWap },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    groupName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      materialTypes: {},
      fields: [
        { key: "title", label: "materialName" },
        { key: "description", label: "materialDescription" },
        { key: "trainingMaterialViewedStr", label: "status" },
        { key: "function", label: "download", width: "150px" },
      ],
    };
  },
  mounted() {
    this.materialTypes = materialTypesEnum;
    this.initData();
  },
  methods: {
    initData() {
      this.value.forEach((item) => {
        this.$refs.selectableTable.addNewRow(item);
      });
    },
    isShowLink(item) {
      return item.materialType == this.materialTypes.website;
    },
    checkURL(URL) {
      var str = URL;

      var Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
      var objExp = new RegExp(Expression);

      return objExp.test(str);
    },

    clickLink(item) {
      if (item.materialType == this.materialTypes.website) {
        if (this.checkURL(item.webURL)) {
          setView({
            trainingMaterialId: item.trainingMaterialId,
            jobId: item.jobId,
            eleClassId: item.eleClassId,
            operationType: 1,
          }).then(() => {
            item.trainingMaterialViewedStr = this.$g(
              "enumTrainingMaterialViewed.viewed"
            );
            window.open(item.webURL, "_blank");
          });
        } else {
          this.$refs.lblMsg.message(repResult.faild, this.$g("invalid-URL"));
        }
      }
    },
    downloadPath(item) {
      isExitFile(item.documentId).then((m) => {
        if (m.data.status === repResult.success) {
          setView({
            trainingMaterialId: item.trainingMaterialId,
            jobId: item.jobId,
            eleClassId: item.eleClassId,
            operationType: 2,
          }).then(() => {
            item.trainingMaterialViewedStr = this.$g(
              "enumTrainingMaterialViewed.viewed"
            );
          });
          downloadFile(item.documentId);
        } else {
          this.$emit("show-message", m.data.status, m.message);
        }
      });
    },
  },
};
</script>
