<template>
  <div class="fieldset">
    <div class="fieldsetTitle" v-if="itemTitle != ''">{{ itemTitle }}</div>
    <div class="fieldsetBody">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemTitle: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      title: "",
    };
  },
};
</script>

