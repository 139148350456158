<template>
  <div class="layoutContent">
    <home-title
      :description="SettingMsg"
      :showpic="
        getConfigVal(curPageConfigAndDefaultPageConfig, 'showPic', 'vIf')
      "
    ></home-title>
    <page-desc
      v-if="courseInfo"
      :desc="courseInfo.classTypeDescription"
      :descTitle="descTitle"
    ></page-desc>
    <k-alert-msg ref="lblMsg" />
    <div v-if="courseInfo">
      <materials
        v-model="courseInfo.materialList"
        :groupName="$g('trainingMaterial')"
        class="contentParty noNavTabCard"
      ></materials>
      <access
        v-model="courseInfo.virtualList"
        :groupName="$g('trainingAccess')"
        class="contentParty noNavTabCard"
        v-if="courseInfo.trainingType == 4"
      ></access>
      <photo-list
        :photoMap="courseInfo"
        :mapPoint="courseInfo.facilityPoint"
        v-if="courseInfo.trainingType == 1"
      ></photo-list>
      <training-quiz
        v-model="courseInfo.quizList"
        groupName="Training Quiz"
        :quizDueDate="courseInfo.quizDueDate"
        v-if="courseInfo.needQuizOnline == 2"
      ></training-quiz>
    </div>
  </div>
</template>

<script>
import { getCourseInfo } from "../../api/training";
import {
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
} from "fe-infrastractures";
import { getPortalPageSetting } from "@/api/home";
import Materials from "./components/materials.vue";
import access from "./components/access.vue";
import TrainingQuiz from "./components/trainingQuiz.vue";
import PhotoList from "./components/photoList.vue";
import { pageNameEnum } from "@/utils/enums/pageNameEnum";
import HomeTitle from "@/views/components/homeTitle";
import PageDesc from "./components/pageDesc.vue";
export default {
  components: {
    Materials,
    TrainingQuiz,
    PhotoList,
    HomeTitle,
    PageDesc,
    access,
  },
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    return {
      curPageConfigAndDefaultPageConfig,
      getConfigVal,
      courseInfo: null,
      SettingMsg: "",
      descTitle: "",
    };
  },
  methods: {},
  async mounted() {
    if (this.$route.query.id) {
      await getCourseInfo(this.$route.query.id).then((res) => {
        this.courseInfo = res.data;
      });
      let pageName = pageNameEnum.TrainingClassOnsiteTraining;
      this.descTitle = this.$g("onsiteTraining");
      if (this.courseInfo.trainingType == "2") {
        pageName = pageNameEnum.TrainingClassOnlineTraining;
        this.descTitle = this.$g("onlineTraining");
      } else if (this.courseInfo.trainingType == "4") {
        pageName = pageNameEnum.TrainingClassVirtualTraining;
        this.descTitle = this.$g("virtualTraining");
      }
      getPortalPageSetting(pageName).then((res) => {
        this.SettingMsg = res.data?.description;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.hand:hover {
  cursor: pointer;
}
</style>
